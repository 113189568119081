<template lang="pug">
  v-container.fill-height
    v-row.flex-column.align-center.justify-center
      v-col(cols="4")
        v-card
          v-card-title.mt-5.justify-center Bonjour, quel est ton prénom ?
          v-card-actions.pa-10
            v-text-field(v-model="userFirstName" @keydown.enter="userStore.$patch({firstName: userFirstName})")
</template>
<script lang="ts">
import {useUserStore} from "@/stores/user";
export default{
  name: 'MainScreen',
  data() {
    return {
      userFirstName: '',
      userStore: useUserStore(),
    };
  }
}
</script>
<style lang="scss">

</style>
