import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => ({
        firstName: '',
        nickName: '',
        email: '',
        age: undefined,
        level: 0,
        points: 0,
    }),
    getters: {
        getFirstName: state => state.firstName,
        getNickName: state => state.nickName,
        getEmail: state => state.email,
        getAge: state => state.age,
        getLevel: state => state.level,
        getPoints: state => state.points,
    },
    actions: {
        updateFirstName(firstName) {
            this.state.firstName = firstName
        },
        updateNickName(nickName) {
            this.state.nickName = nickName
        },
        updateEmail(email) {
            this.state.email = email
        },
        updateLevel(level) {
            this.state.level = level
        },
        updatePoints(points) {
            this.state.firstName = points
        },
    }
})