<template lang="pug">
  v-app
    v-main
      div {{userStore.getFirstName}}
      div {{userStore.getNickName}}
      game-alphabet
      //game-alphabet(v-if="userStore.getFirstName" )
      //main-screen(v-else)
</template>

<script>
import GameAlphabet from "@/components/game-alphabet.vue";
import {useUserStore} from "@/stores/user";
import MainScreen from "@/components/main-screen.vue";

export default {
  name: 'App',

  components: {
    MainScreen,
    GameAlphabet,
  },

  data() {
    return {
      userStore: useUserStore(),
    };
  },
  computed: {
  },
  mounted() {
  }
};
</script>
<style lang="scss">
body .v-application main{
  background-image: url('./assets/school.jpg');
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
  .v-main__wrap{
    background: rgba(255,255,255,0.75);
  }
}
</style>