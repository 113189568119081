<template lang="pug">
v-container(fluid).fill-height(:class="getWordCompliance? 'done' : 'ongoing'")
  fireworks-success.overlayed(v-show="getWordCompliance")
  v-row.flex-column.align-center.justify-center
    v-col.mt-auto.mb-auto(cols="6")
      v-card(color="lightgrey" )
        v-card-title.word-title.justify-center.align-center.text-center.text-uppercase {{currentWord}}
        v-img.ml-auto.mr-auto(:src="require(`../assets/images/${currentWord}.webp`)" width="300" height="300" contain v-if="hasIllustration" )
        div.color-square(:style="`background-color:${getColor}`" v-else)
        //v-card-text.word-predict.justify-center.align-center.text-center.text-uppercase(:class="getLetterCompliance ? '' : 'invalid'") {{getTextVal}}{{getLetterPredict}}
        v-card-subtitle.letter-predict.justify-center.align-center.text-center.text-uppercase(:class="getLetterCompliance ? '' : 'invalid'") {{getLetterPredict}}
          audio(ref="audio" :src="require(`../assets/audio/${getLetterPredict}.mp3`)" v-if="getLetterPredict" autoplay=true)
        v-card-actions.word-input.justify-center.align-center.text-center.text-uppercase
          v-alert(color="green" v-show="getWordCompliance")
            span.bravo BRAVO !!
    v-col.mt-auto.mb-auto(cols="3")
      v-text-field(v-model="textVal" @input="validateTextVal($event)" ref="wordField" autofocus
      :color="getLetterCompliance ? 'green' : 'red'"  :background-color="getLetterCompliance ? '' : 'red'"
      @keydown.enter="getRandomWordFromList"
      @blur="stayFocus" height="60" v-show="!getWordCompliance" :disabled="!getLetterCompliance")
</template>
<script lang="ts">
import wordList from '@/enum/word-list';
import FireworksSuccess from "@/components/fireworks-success.vue";
export default{
  name: 'GameAlphabet',
  components: {FireworksSuccess},

  data() {
    return {
      textVal: '',
      currentWord: undefined,
      wordList,
    };
  },
  watch: {
    getWordCompliance(isCompliant) {
      if (isCompliant) {
        setTimeout(() => {
          this.textVal = '';
          this.getRandomWordFromList();
        }, 3000);
      }
    },
    getLetterCompliance(isCompliant) {
      if (!isCompliant) {
        setTimeout(() => {
          console.log('test');
          this.textVal = this.textVal.substring(0, this.textVal.length-1);
          this.stayFocus();
        }, 1000);
      }
    },
  },
  computed: {
    getTextVal() {
      return this.textVal;
    },
    getColor() {
      switch (true) {
        case (this.currentWord === 'bleu'):
          return 'blue';

        case (this.currentWord === 'vert'):
          return 'green';

        case (this.currentWord === 'violet'):
          return 'purple';

        case (this.currentWord === 'rose'):
          return 'pink';

        case (this.currentWord === 'rouge'):
          return 'red';

        case (this.currentWord === 'marron'):
          return 'saddlebrown';

        case (this.currentWord === 'jaune'):
          return 'gold';

        case (this.currentWord === 'orange'):
          return 'darkorange';

        default:
          return '';
      }
    },
    getLetterCompliance() {
      const letterIndex = this.textVal.length - 1;
      return this.textVal.length > 0 ? this.currentWord.charAt(letterIndex).toUpperCase() === this.textVal.charAt(letterIndex).toUpperCase() : true;
    },
    getWordCompliance() {
      return this.textVal.length > 0 ? this.currentWord.toUpperCase() === this.textVal.toUpperCase() : false;
    },
    getLetterPredict() {
      const letterIndex = this.textVal.length;
      return this.getLetterCompliance ? this.currentWord.charAt(letterIndex) : this.currentWord.charAt(letterIndex-1);
    },
    hasIllustration() {
      const excludedList = [
        'orange',
        'rouge',
        'bleu',
        'jaune',
        'vert',
        'violet',
        'marron',
      ];
      return excludedList.indexOf(this.currentWord) < 0;
    },
  },
  methods: {
    validateTextVal(textFromInput) {
      console.log(textFromInput);
    },
    getRandomWordFromList() {
      const oldWord =  this.currentWord;
      this.currentWord = wordList[Math.floor(Math.random()*wordList.length)];
      if (this.currentWord === oldWord) {
        this.currentWord = wordList[Math.floor(Math.random()*wordList.length)];
      }
    },
    stayFocus() {
      this.$nextTick(() => {
        const input = this.$refs.wordField;
        input.$el.querySelector('input').focus();
      });
    },
  },
  beforeMount() {
    this.getRandomWordFromList();
  },
}
</script>
<style lang="scss">
.global-wrapper{
  color: darkseagreen;
}
.word-title{
  font-size: 30px;
  padding: 50px;
  font-weight: bold;
  letter-spacing: 2px;
}
.word-predict{
  font-size: 25px;
  padding: 30px;
  color: palevioletred !important;

  &.invalid{
    color: red !important;
  }
}
.letter-predict{
  font-size: 50px;
  padding: 50px;
  color: midnightblue !important;

  &.invalid{
    color: red !important;
  }
}
.word-input{
  margin: 0 20%;
}
.color-square{
  width: 300px;
  height: 300px;
  margin: auto;
}
.v-text-field input{
  text-align: center;
  text-transform: uppercase;
  font-size: 35px;
}
.overlayed{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}
.bravo{
  font-weight: bold;
  color: white;
}
.done{
  background: darkseagreen;
}
.ongoing{
  background: linen;
}
</style>
