export default [
    'crocodile',
    'robot',
    'chat',
    'chien',
    'maison',
    'voiture',
    'avion',
    'oiseau',
    'camion',
    'frigo',
    'lit',
    'livre',
    'biberon',
    'cuisine',
    'train',
    'orange',
    'rouge',
    'bleu',
    'jaune',
    'vert',
    'violet',
    'marron',
    'koala',
    'arbre',
    'ballon',
    'cube',
];